import React, { useMemo } from 'react';
import { Container } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { IndexHowItWorksBigCTA } from '../index/IndexHowItWorksBigCTA';
import { useDownloadCounter } from '../index/IndexDownloadsInfo';

export const MoveFromToRatings: React.FC = () => {
  const [downloadsCounter] = useDownloadCounter();
  const shortDownloadsCounter = useMemo(() => `${Math.round(downloadsCounter / 1000000)}M`, [downloadsCounter]);
  return (
    <>
      <Container>
        <div className="main-header text-center pt-5 pt-sm-5 mt-sm-5 mb-5">
          <FormattedMessage
            id="web.move.ratings.title"
            defaultMessage="Join millions moving their playlists with Free Your Music"
          />
        </div>
        <div className="d-flex flex-row flex-wrap justify-content-center text-center mb-4">
          <div className="d-flex flex-column flex-nowrap ratings-box align-items-center justify-content-center p-5">
            <div className="ratings-icon">
              <i className="fa-solid fa-star" />
              <i className="fa-solid fa-star" />
              <i className="fa-solid fa-star" />
              <i className="fa-solid fa-star" />
              <i className="fa-light fa-star" />
            </div>
            <div className="main-header">
              <FormattedMessage id="web.move.ratings.text1" defaultMessage="4.2 Stars" />
            </div>
            <p className="text-muted pt-4 m-0">
              <FormattedMessage id="web.move.ratings.description1" defaultMessage="Average rating" />
            </p>
          </div>
          <div className="d-flex flex-column ratings-box align-items-center justify-content-center p-5">
            <div className="ratings-icon">
              <i className="fa-solid fa-medal" />
            </div>
            <div className="main-header">
              <FormattedMessage id="web.move.ratings.text2" defaultMessage="23,200 Ratings" />
            </div>
            <p className="text-muted pt-4 m-0">
              <FormattedMessage id="web.move.ratings.description2" defaultMessage="On iOS and Android" />
            </p>
          </div>
          <div className="d-flex flex-column ratings-box align-items-center justify-content-center p-5">
            <div className="ratings-icon">
              <i className="fa-solid fa-users" />
            </div>
            <div className="main-header">
              <FormattedMessage
                id="web.move.ratings.text3"
                defaultMessage="{numberOfDownloads} Downloads"
                values={{ numberOfDownloads: shortDownloadsCounter }}
              />
            </div>
            <p className="text-muted pt-4 m-0">
              <FormattedMessage id="web.move.ratings.description3" defaultMessage="Across all platforms" />
            </p>
          </div>
        </div>
        <IndexHowItWorksBigCTA className="max-auto" />
      </Container>
    </>
  );
};
