import React, { useCallback, useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import Link from 'next/link';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { FymButton } from '../components/FymButton';
import { GenericNavbar } from './GenericNavbar';
import { BlackFridayHelloBar } from '../containers/pages/components/hellobars/BlackFridayHelloBar';
import { CookieConsentBar } from '../components/CookieConsentBar';
import { AnniversaryHelloBar } from '../containers/pages/components/hellobars/AnniversaryHelloBar';
import { MusicService } from '../components/musicServices/services/types';
import { popularMusicServices } from '../components/musicServices/allMusicServices';

const MusicServiceTransferLink: React.FC<{
  from: MusicService;
  to: MusicService;
}> = ({ from, to }) => {
  return (
    <DropdownItem>
      <Link prefetch={false} href={`/transfer-${from.id}-to-${to.id}`} className="nav-link">
        <FormattedMessage
          id="web.carousel.transfer_x_to_y"
          defaultMessage="Transfer {fromMusicService} to {toMusicService}"
          values={{
            fromMusicService: <span style={{ color: from.textColor ?? from.color }}>{from.shortName}</span>,
            toMusicService: <span style={{ color: to.textColor ?? to.color }}>{to.shortName}</span>
          }}
        />
      </Link>
    </DropdownItem>
  );
};
export const NavbarComponent: React.FC = React.memo(() => {
  const [isOpen, setIsOpen] = useState(false);
  const close = useCallback(() => setIsOpen(false), []);
  const router = useRouter();
  const raw = (router.query.raw as string) === '1';

  if (raw) {
    return null;
  }

  return (
    <>
      <BlackFridayHelloBar />
      <AnniversaryHelloBar />
      <CookieConsentBar />
      <GenericNavbar
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        rightContent={
          <Nav navbar className={classNames('order-xl-12', 'ms-auto ord')}>
            <NavItem className="px-2">
              <Link prefetch={false} href="/download" passHref>
                <FymButton onClick={close} outline className="me-5">
                  <FormattedMessage id="web.nav.try_now" defaultMessage="Transfer your music now" />
                </FymButton>
              </Link>
            </NavItem>
          </Nav>
        }
        additionalLeftNavItems={
          <>
            <NavItem className="px-2">
              <Link prefetch={false} href="/pricing" className="nav-link" key="pricing" onClick={close}>
                <FormattedMessage id="web.nav.pricing" defaultMessage="Pricing" />
              </Link>
            </NavItem>

            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret className="px-2">
                <FormattedMessage id="web.nav.how_to.title" defaultMessage="How to" />
              </DropdownToggle>
              <DropdownMenu>
                {popularMusicServices.map(({ from, to }) => (
                  <MusicServiceTransferLink from={from} to={to} key={`${from.id}-${to.id}`} />
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem className="px-2">
              <Link prefetch={false} href="/blog" className="nav-link" key="blog" onClick={close}>
                <FormattedMessage id="web.footer.blog" defaultMessage="Blog" />
              </Link>
            </NavItem>
            <NavItem className="px-2">
              <NavLink
                key="faq"
                onClick={close}
                href="https://support.freeyourmusic.com/helpdesk/"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <FormattedMessage id="web.nav.faq" defaultMessage="FAQ" />
              </NavLink>
            </NavItem>
            <NavItem className="px-2">
              <Link prefetch={false} href="/about" className="nav-link" key="about-us" onClick={close}>
                <FormattedMessage id="web.about.page_title" defaultMessage="About Us" />
              </Link>
            </NavItem>
          </>
        }
      />
    </>
  );
});
