import React from 'react';
import { NextPage } from 'next';
import Head from 'next/head';
import { defineMessages, useIntl } from 'react-intl';
import { IndexTop } from './index/IndexTop';
import { Footer } from '../../layouts/Footer';
import { IndexSupport } from './index/IndexSupport';
import { IndexCTA } from './index/IndexCTA';
import { premiumQuotes } from './premium/quotes';
import { Features } from './features/Features';
import { features } from './features/consts';
import { QuotesView } from './premium/QuotesView';
import { IndexBlogPosts } from './index/IndexBlogPosts';
import { IndexTalkingAboutUs } from './index/IndexTalkingAboutUs';
import { spotify } from '../../components/musicServices/services/Spotify';
import { appleMusic } from '../../components/musicServices/services/AppleMusic';
import { MoveFromToRatings } from './move/MoveFromToRatings';
import { BlogPostsResponse } from '../blog/types';
import { MoveFromPopularTransfers } from './move/MoveFromPopularTransfers';

const { pageTitle } = defineMessages({
  pageTitle: {
    id: 'web.main_title',
    defaultMessage: 'Free Your Music: Ultra Simple Transfer of Playlists'
  }
});

export const Home: NextPage<{
  blogPosts: BlogPostsResponse;
}> = React.memo(({ blogPosts }) => {
  const intl = useIntl();

  return (
    <>
      <Head>
        <title>{intl.formatMessage(pageTitle)}</title>
      </Head>
      <IndexTop />
      <MoveFromPopularTransfers from={spotify} />
      <MoveFromPopularTransfers from={appleMusic} />
      <MoveFromToRatings />
      <Features
        title={intl.formatMessage({
          id: 'web.home.selling-points.title',
          defaultMessage: 'Powerful Playlist  Management Tools'
        })}
        features={features}
      />
      <QuotesView quotes={premiumQuotes} />
      <IndexSupport />
      <IndexTalkingAboutUs />

      <IndexCTA />
      <IndexBlogPosts blogPosts={blogPosts} />

      <Footer />
    </>
  );
});
