import React, { useMemo } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { defineMessages, useIntl } from 'react-intl';
import Link from 'next/link';
import { MusicService } from '../../../components/musicServices/services/types';
import { spotify } from '../../../components/musicServices/services/Spotify';
import { amazonMusic } from '../../../components/musicServices/services/AmazonMusic';
import { appleMusic } from '../../../components/musicServices/services/AppleMusic';
import { deezer } from '../../../components/musicServices/services/Deezer';
import { soundcloud } from '../../../components/musicServices/services/SoundCloud';
import { tidal } from '../../../components/musicServices/services/Tidal';
import { youtube } from '../../../components/musicServices/services/YouTube';
import { youtubeMusic } from '../../../components/musicServices/services/YouTubeMusic';
import { pandora } from '../../../components/musicServices/services/Pandora';
import { napster } from '../../../components/musicServices/services/Napster';

const { message1, message2, message3, message4, message5, message6, message7, message8, message9 } = defineMessages({
  message1: {
    id: 'web.popular-transfers.message1',
    defaultMessage: 'Transfer your favorite music'
  },
  message2: {
    id: 'web.popular-transfers.message2',
    defaultMessage: 'Transfer your playlists and music'
  },
  message3: {
    id: 'web.popular-transfers.message3',
    defaultMessage: 'Convert your playlists & favorites'
  },
  message4: {
    id: 'web.popular-transfers.message4',
    defaultMessage: 'Convert your favorite music'
  },
  message5: {
    id: 'web.popular-transfers.message5',
    defaultMessage: 'Move your music'
  },
  message6: {
    id: 'web.popular-transfers.message6',
    defaultMessage: 'Move your best-loved music'
  },
  message7: {
    id: 'web.popular-transfers.message7',
    defaultMessage: 'Shift your songs'
  },
  message8: {
    id: 'web.popular-transfers.message8',
    defaultMessage: 'Transfer your music'
  },
  message9: {
    id: 'web.popular-transfers.message9',
    defaultMessage: 'Transfer your playlists'
  }
});

const allMessages = [message1, message2, message3, message4, message5, message6, message7, message8, message9];

const MoveFromPopularTransferElement: React.FC<{
  from: MusicService;
  to: MusicService;
  index: number;
}> = ({ from, to, index }) => {
  const intl = useIntl();
  const message = useMemo(() => allMessages[index % allMessages.length], [index]);
  const [switchedFrom, switchedTo]: [MusicService, MusicService] = useMemo(() => {
    if (index % 2 === 0) {
      return [from, to];
    }
    return [to, from];
  }, [from, index, to]);
  return (
    <Col xs={12} md={5} className="popular-transfer-element">
      <Link
        href={`/transfer-${switchedFrom.id}-to-${switchedTo.id}`}
        className="d-flex flex-column text-body text-center flex-grow-1 h-100"
      >
        <div>{intl.formatMessage(message)}</div>
        <div>
          {intl.formatMessage(
            {
              id: 'web.popular-transfers.from_to_names',
              defaultMessage: 'from {musicServiceNameFrom} to {musicServiceNameTo}'
            },
            {
              musicServiceNameFrom: (
                <span style={{ color: switchedFrom.textColor ?? switchedFrom.color }}>{switchedFrom.shortName}</span>
              ),
              musicServiceNameTo: (
                <span style={{ color: switchedTo.textColor ?? switchedTo.color }}>{switchedTo.shortName}</span>
              )
            }
          )}
        </div>
      </Link>
    </Col>
  );
};

const popularMusicServices = [
  amazonMusic,
  appleMusic,
  deezer,
  soundcloud,
  spotify,
  tidal,
  youtube,
  youtubeMusic,
  pandora,
  napster
];
export const MoveFromPopularTransfers: React.FC<{
  from?: MusicService;
}> = ({ from }) => {
  const filteredMusicServices = useMemo(
    () => popularMusicServices.filter((ms) => ms.id !== (from ?? spotify).id).slice(0, allMessages.length),
    [from]
  );
  const intl = useIntl();
  return (
    <Container>
      <div className="main-header text-center pt-5 pt-sm-5 mt-sm-5 mb-5">
        {from !== undefined
          ? intl.formatMessage(
              {
                id: 'web.popular-transfers.title',
                defaultMessage: 'Most popular {musicServiceName} transfers:'
              },
              {
                musicServiceName: (
                  <Link
                    href={`transfer-move-your-music-from-${from.id}`}
                    style={{ color: from.textColor ?? from.color }}
                  >
                    {from.shortName}
                  </Link>
                )
              }
            )
          : intl.formatMessage({
              id: 'web.popular-transfers.title-no-ms',
              defaultMessage: 'Most popular transfers:'
            })}
      </div>
      <Row className="justify-content-center mx-0">
        {filteredMusicServices.map((ms, index) => {
          return <MoveFromPopularTransferElement index={index} from={from ?? spotify} to={ms} key={ms.id} />;
        })}
      </Row>
    </Container>
  );
};
