import React from 'react';
import { FormattedMessage } from 'react-intl';
import Link from 'next/link';
import classNames from 'classnames';

export const IndexCTA: React.FC = React.memo(() => {
  return (
    <div className="py-5 my-5">
      <div className="py-5 my-5">
        <div className="text-center">
          <p className="smaller-header text-center mt-5 mb-4">
            <FormattedMessage
              id="web.home.cta.truly-yours"
              defaultMessage="Free Your Music makes your playlists truly yours. "
            />
          </p>

          <p className="lead text-center">
            <FormattedMessage
              id="web.home.cta.join.1"
              defaultMessage="Take your playlists when you transfer to a new music streaming service."
            />
          </p>
          <p className="lead text-center">
            <FormattedMessage
              id="web.home.cta.join.2"
              defaultMessage="Be part of the 5 million other music lovers who moved their music with Free Your Music."
            />
          </p>
          <div className={classNames('py-5 py-md-3 premium-big-cta-container')}>
            <Link prefetch={false} href="/download/" className="premium-big-cta">
              <div className="premium-big-cta-price">
                <FormattedMessage id="web.home.cta.transfer-now" defaultMessage="Transfer now" />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
});
