import React from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import Image from 'next/image';
import { useMediaQuery } from 'react-responsive';
import { screenshotAsset } from '../../../components/screenshotNextAsset';
import { AvailableLanguages } from '../../../redux/appTypes';
import { useIsClient } from '../../../components/hooks/useIsClient';
import { ScreenshotsName } from '../../../components/screenshotNextAsset.imports';

export const MobileDesktopScreenshot: React.FC<{
  className?: string;
  mobileScreenshot?: ScreenshotsName;
  desktopScreenshot?: ScreenshotsName;
}> = React.memo(
  ({ className, mobileScreenshot = 'mobile-screenshot-4-dark', desktopScreenshot = 'desktop-screenshot-1-dark' }) => {
    const { locale } = useRouter();
    const intl = useIntl();

    const isClient = useIsClient();
    const isBigScreen = useMediaQuery({ query: '(min-width: 1200px)' });
    return (
      <div className={classNames('devices-example ', className)}>
        {isClient && isBigScreen && (
          <div className="devices-example-inner">
            {/* Dark Only */}
            <div className="device-phone">
              <Image
                src={screenshotAsset(mobileScreenshot, locale as AvailableLanguages | undefined)}
                alt={intl.formatMessage({
                  id: 'web.mobile-desktop-screenshots.ios',
                  defaultMessage: 'Free Your Music app select items ios screen'
                })}
                priority
                placeholder="blur"
                className="img-fluid"
                fill
                sizes="100vw"
                style={{
                  objectFit: 'contain',
                  objectPosition: 'left'
                }}
              />
            </div>
            <Image
              src={screenshotAsset(desktopScreenshot, locale as AvailableLanguages | undefined)}
              alt={intl.formatMessage({
                id: 'web.mobile-desktop-screenshots.desktop',
                defaultMessage: 'Free Your Music app matched songs desktop screen'
              })}
              priority
              placeholder="blur"
              className="img-fluid device-desktop"
              style={{
                maxWidth: '100%',
                height: 'auto'
              }}
            />
          </div>
        )}
      </div>
    );
  }
);
