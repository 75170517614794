import React, { useCallback } from 'react';
import { scroller } from 'react-scroll';
import { useRouter } from 'next/router';

export const makePageTitle = (title: string): string => (title ? `Free Your Music - ${title}` : 'Free Your Music');

export function getParameterByName(name: string, url: string): string | null {
  const parsedName = name.replace(/[[\]]/g, '\\$&');

  const regex = new RegExp(`[?&]${parsedName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const useScrollToHashPosition = (
  hash: string,
  href: string,
  as?: string,
  duration = 600
): [
  {
    href: string;
    as: string;
  },
  (event: React.MouseEvent<HTMLElement>) => void
] => {
  const router = useRouter();
  const hashedAs = `${as}#${hash}`;
  const shallow = as === router.asPath;
  return [
    {
      href: router.pathname,
      as: hashedAs
    },
    useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        if (shallow) {
          scroller.scrollTo(hash, {
            duration,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -60
          });
          router.push(`${router.pathname}#${hash}`, hashedAs, { shallow: true }).catch(console.error);
        } else {
          router
            .push(`${href}#${hash}`, hashedAs, { shallow: false })
            .then(() => {
              scroller.scrollTo(hash, {
                duration,
                delay: 0,
                smooth: 'easeInOutQuart',
                offset: -60
              });
            })
            .catch(console.error);
        }
        event.preventDefault();
      },
      [duration, hash, hashedAs, href, router, shallow]
    )
  ];
};
